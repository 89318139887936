module.exports = {
  displayFlashMessage: (message, redirectUrl) => {
    const template = document.getElementById('flash-message-template');
    const clone = document.importNode(template.content, true);
    const messageContainer = clone.querySelector('[data-alert-message]');
    messageContainer.textContent = message;
    if (redirectUrl) {
      const alertContainer = clone.querySelector('[data-controller~="alert"]');
      alertContainer.dataset.alertRedirectUrl = redirectUrl;
    }
    document.querySelector('#flash-container').appendChild(clone);
  }
};