import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.changePlaceholder();
  } 

  changePlaceholder () {
    const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (w < 600) {
      this.element.placeholder = 'Né le JJ/MM/AAAA *';
    } else {
      this.element.placeholder = 'Date de naissance JJ/MM/AAAA *';
    }
  }
}