import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selector'];

  connect () {
  }

  focus (event) {
    event.preventDefault();
    document.querySelector(this.data.get("selector")).focus();
  }
}