import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['desktopBar', 'desktopOverlay', 'desktopContainer', 'mobileContent', 'desktopBurger', 'mobileBurger']
    static classes = ['active', 'burgerOpen'] 

    connect () {}

    // --------------------- DESKTOP MENU

    handleDesktopTrigger = (event) => {
      if (this.desktopBarTarget.classList.contains(`Menu-bar--${this.activeClass}`)) {
        this.desktopBarTarget.classList.remove(`Menu-bar--${this.activeClass}`);
        this.desktopOverlayTarget.classList.remove(`Menu-overlay--${this.activeClass}`);
        this.desktopContainerTarget.classList.remove(`Menu-container--${this.activeClass}`);
      } else {
        this.desktopBarTarget.classList.add(`Menu-bar--${this.activeClass}`);
        this.desktopOverlayTarget.classList.add(`Menu-overlay--${this.activeClass}`);
        this.desktopContainerTarget.classList.add(`Menu-container--${this.activeClass}`);
      }

      this.handleBurgerIcon(this.desktopBurgerTarget)
    }

    // --------------------- MOBILE MENU

    handleMobileTrigger = (event) => {
      if (this.mobileContentTarget.classList.contains(`MobileMenu-content--${this.activeClass}`)) {
        this.mobileContentTarget.classList.remove(`MobileMenu-content--${this.activeClass}`);
      } else {
        this.mobileContentTarget.classList.add(`MobileMenu-content--${this.activeClass}`);
      }
      this.handleBurgerIcon(this.mobileBurgerTarget)
    }

    handleBurgerIcon = (target) => {
      if (target.classList.contains(`${this.burgerOpenClass}`)) {
        target.classList.remove(`${this.burgerOpenClass}`);
      } else {
        target.classList.add(`${this.burgerOpenClass}`);
      }
    }
}