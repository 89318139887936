import { Controller } from "stimulus";
import IMask from 'imask';
// import Inputmask from "inputmask";

export default class extends Controller {

  connect() {
    // Inputmask("datetime", { inputFormat: "dd/mm/yyyy", outputFormat: "dd/mm/yyyy", jitMasking: true }).mask(this.element);
    if(this.element.type != 'date') {
      const dayMonthYear = this.element.value.split('/');
      const date = new Date(dayMonthYear[2], dayMonthYear[1] - 1, dayMonthYear[0]);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();

      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;

      this.element.value = [day, month, year].join('/');

      let minDate;
      if (this.element.min) {
        const minYearMonthDay = this.element.min.split('-')
        minDate = new Date(minYearMonthDay[0], minYearMonthDay[1] - 1, minYearMonthDay[2]);
      }

      let maxDate;
      if (this.element.max) {
        const maxYearMonthDay = this.element.max.split('-')
        maxDate = new Date(maxYearMonthDay[0], maxYearMonthDay[1] - 1, maxYearMonthDay[2]);
      }

      this.mask = IMask(this.element, {
        mask: Date,
        min: minDate,
        max: maxDate,
        pattern: 'd/m/Y',  // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
        // you can provide your own blocks definitions, default blocks for date mask are:
        blocks: {
          d: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
            placeholderChar: 'J',
          },
          m: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
            placeholderChar: 'M',
          },
          Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 9999,
            placeholderChar: 'A',
          },
        },
        // define date -> str convertion
        format: function (date) {
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();

          if (day < 10) day = "0" + day;
          if (month < 10) month = "0" + month;

          return [day, month, year].join('/');
        },
        // define str -> date convertion
        parse: function (str) {
          var dayMonthYear = str.split('/');
          return new Date(dayMonthYear[2], dayMonthYear[1] - 1, dayMonthYear[0]);
        },
        lazy: true, 
        autofix: true, // try to correct the entry if it does not meet the constraints
        overwrite: true, // can overwrite already entered date
      });
    }
  } 

  disconnect() {
    // Inputmask.remove(this.element);
    if(this.mask) {
      this.mask.destroy();
    }
  }
}