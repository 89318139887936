import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selector'];

  connect () {
    let target;
    if (this.data.get("selector")) {
      target = document.querySelector(this.data.get("selector"));
    } else { 
      if (window.location.hash) {
        target = document.querySelector(window.location.hash);
      }
    }
    if (target) {
      const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ // behavior: 'smooth' is also possible
        top: targetPosition 
      });
    }
  }
}