import { Controller } from "stimulus";
import Rails from '@rails/ujs';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  static targets = ['field', 'errorMsgContainer']
  static classes = ['inputError']
  static values = {
    phoneNumber: String,
    preventSubmitOnError: Boolean
  }

  connect() {
    this.iti = intlTelInput(this.fieldTarget, {
      utilsScript: "/intl-tel-input-utils.js", 
      hiddenInput: "phone",
      separateDialCode: true,
      initialCountry: "",
      preferredCountries: ["fr", "be", "ch", "de", "es", "ie", "it", "lu"]
    });
    if (this.phoneNumberValue.length) {
      this.iti.setNumber(this.phoneNumberValue)
    }
    if (this.preventSubmitOnErrorValue) {
      this.boundOnFormSubmit = this.onFormSubmit.bind(this);
      this.form = this.element.closest('form');
      this.form.addEventListener('submit', this.boundOnFormSubmit);
    }
  } 

  disconnect() {
    this.iti.destroy();
    if (this.preventSubmitOnErrorValue) {
      this.form.removeEventListener('submit', this.boundOnFormSubmit);
    }
  }

  onFormSubmit(event) {
    if (this.iti.isValidNumber()) {
      return true;
    }
    else {
      event.preventDefault();
      if (this.hasErrorMsgContainerTarget) {
        this.fieldTarget.classList.add(this.inputErrorClass);
        this.errorMsgContainerTarget.style.display = 'block';     
      }
      // timeout here because Rails disable submit btn after this function 
      setTimeout(() => {
        Rails.enableElement(this.form);
      }, 500); 
      return false;
    }
  }
}