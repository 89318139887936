import './stylesheets/index';
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import StimulusReflex from 'stimulus_reflex';
import consumer from './channels/consumer';
import { insertGTMScript } from '../shared/scripts/google_tag_manager';
import '../shared/scripts/turbolinks_utils';

const application = Application.start();
const context = require.context("./controllers", true, /_controller\.js$/);
const sharedContext = require.context("../shared/controllers", true, /_controller\.js$/);

application.load(definitionsFromContext(context));
application.load(definitionsFromContext(sharedContext));

StimulusReflex.initialize(application, { consumer, debug: true })

insertGTMScript();

