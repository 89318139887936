import { Controller } from 'stimulus';
import debounce from 'lodash.debounce';
// Do not remove IScroll import even if it's not explicitly called
import IScroll from 'fullpage.js/vendors/scrolloverflow.min';
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

export default class extends Controller {
    static targets = ['sectionHome', 'section1', 'section2', 'section3', 'section4', 'section5', 'section6', 'mobileScrollButton']
    static classes = ['reverse']
    

    connect() {
      this.lastSectionHeightPercentage = this.section6Target.offsetHeight / 5;
      document.addEventListener('scroll', this.debounce(this.storeScroll), { passive: true });
      this.storeScroll(); 
      this.myFullPage = this.newFullPage();
      this.debouncedReloadFullPage = debounce(this.reloadFullPage, 500).bind(this);
      window.addEventListener('resize', this.debouncedReloadFullPage);
   }

    newFullPage() {
      return new fullpage('#fullpage', {
        menu: ".menu-highlight",
        anchors: ['tantieme','fut','tantieme-les-anges','fut-a-la-bouteille1', 'fut-a-la-bouteille2', 'transmettre', 'parrainer'],
        responsiveWidth: 1025,
        autoScrolling: window.innerWidth > 1024,
        bigSectionsDestination: 'top',
        licenseKey: window.secrets.fullpageLicenseKey,
        scrollOverflow: window.innerWidth > 1024,
        scrollOverflowOptions: {
          scrollbars: false,
        },
        animateAnchor: false,
        afterRender: () => {
          document.querySelector('section').classList.add('activeSection');
        },
        onLeave: (origin, destination, direction) => {
          destination.item.classList.add('activeSection');
        },
        afterLoad: (origin, destination, direction) => {
          if(direction === "up"){
              origin.item.classList.remove('activeSection');
          }
        },
      });
    }

    reloadFullPage() {
      fullpage_api.destroy('all');
      this.myFullPage = this.newFullPage();
    }

    disconnect() {
      if (this.myFullPage) {
        fullpage_api.destroy('all');
      }
      window.removeEventListener('resize', this.debouncedReloadFullPage);
    }

    // -------------------------------- SCROLL WHEN ARROW IS CLICKED
    moveToSection = async () => {
      await this.myFullPage;
      const currentSection = fullpage_api.getActiveSection();
      if(currentSection.isLast) {
        const sections = document.querySelectorAll('section');
        sections.forEach(s => {
            s.classList.remove('activeSection');
        });
        sections[0].classList.add('activeSection');
        this.myFullPage.moveTo(1);
      } else {
        this.myFullPage.moveTo(currentSection.index + 2);
      }
    }

    getDocHeight = () => {
      const D = document;
      return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
      );
    }  
    
    debounce = (fn) => {
      let frame;
      return (...params) => {
        if (frame) { 
          cancelAnimationFrame(frame);
        }
        frame = requestAnimationFrame(() => {
          fn(...params);
        });
      } 
    };

    // -------------------------------- STORE SCROLL VALUE AS DATA ATTRIBUTE
    // Using this data in css to display style like html:not([data-scroll='0'])

    storeScroll = async () => {
      document.documentElement.dataset.scroll = window.scrollY;  
      await this.myFullPage;
      const sections = document.querySelectorAll('section');
      sections.forEach(section => {
        if(section.getBoundingClientRect().top < (window.innerHeight * 0.8)) {
          section.classList.add('activeSection');
        } else {
          section.classList.remove('activeSection');
        }
      });
      
      // Changing arrow orientation when bottom is reached
      if(window.scrollY + window.innerHeight >= this.getDocHeight() - this.lastSectionHeightPercentage) {
        this.mobileScrollButtonTarget.classList.add(this.reverseClass);
      } else {
        this.mobileScrollButtonTarget.classList.remove(this.reverseClass);
      }
    }
}