import { Controller } from 'stimulus'
import throttle from 'lodash.throttle'

export default class extends Controller {
  static classes = [ "active" ]
  static targets = [ "pill" ]
  static values = {
    throttleDelay: Number
  }

  initialize () {
    if (this.data.get('container')) {
      this.container = document.querySelector(this.data.get('container'));
      this.listeningElement = this.container;
    } else {
      this.container = document.documentElement;
      this.listeningElement = window;
    }
    this.refreshPills = this.refreshPills.bind(this);
  }

  connect () {
    const interval = 100 / (this.pillTargets.length - 1);
    this.ranges = [];
    let intervalCenter;
    for (let i = 0; i < this.pillTargets.length; i++) {
      intervalCenter = i * interval;
      this.ranges.push({ min: intervalCenter - interval/2, center: intervalCenter, max: intervalCenter + interval/2 });
    }

    const delay = this.throttleDelayValue || 15;

    if (delay > 0) {
      this.refreshPills = throttle(this.refreshPills, delay);
    }

    this.listeningElement.addEventListener('scroll', this.refreshPills, { passive: true });
    this.refreshPills();
  }

  disconnect () {
    this.listeningElement.removeEventListener('scroll', this.refreshPills);
  }

  refreshPills () {
    const height = this.container.scrollHeight - this.container.clientHeight;
    const position = (this.container.scrollTop / height) * 100; // normalized value between 0 and 100

    this.pillTargets.forEach((pill, i) => {
      if(position >= this.ranges[i].min && position < this.ranges[i].max) {
        pill.classList.add(this.activeClass);
      } else {
        pill.classList.remove(this.activeClass);
      }
    });
  }

  scrollTo (event) {
    const idx = this.pillTargets.findIndex((pill) => pill == event.target );
    const intervalCenter = this.ranges[idx].center;
    const height = this.container.scrollHeight - this.container.clientHeight;
    const scrollToPosition = (intervalCenter/100) * height;
    this.container.scrollTo({ 
      top: scrollToPosition, 
      behavior: 'smooth'
    });
  }
}