import ReflexController from './reflex_controller';

export default class extends ReflexController {
  static targets = [ 'productCheckbox', 'removeProduct' ]

  connect() {    
    super.connect();
  }

  updateProductsIndex (event) {
    event.preventDefault();
    this.stimulate('ProductsSelectionReflex#update_products_index', event.target, { selectors: '' }, this.selectedProducts.map((c)=> c.value));
  }

  removeProduct(event) {
    const productId = event.currentTarget.dataset["productId"];
    const checkbox = this.productCheckboxTargets.find((checkbox) => { return checkbox.value == productId });
    checkbox.checked = false;
    checkbox.dispatchEvent(new Event('input')); 
  }

  get selectedProducts() {
    return this.productCheckboxTargets.filter((c) => { return c.checked });
  }
}
