import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['productInfos', 'productBasket', 'productClose']
    static classes = ['active']

    connect () {}

    mobileProductTrigger = () => {
      if (this.productInfosTarget.classList.contains(`Checkout_minfos--${this.activeClass}`)) {
        this.productInfosTarget.classList.remove(`Checkout_minfos--${this.activeClass}`);
        this.productBasketTarget.classList.remove(`hidden`);
        this.productCloseTarget.classList.add(`hidden`);
      } else {
        this.productInfosTarget.classList.add(`Checkout_minfos--${this.activeClass}`);
        this.productBasketTarget.classList.add(`hidden`);
        this.productCloseTarget.classList.remove(`hidden`);
      }
    }
}