import { Controller } from "stimulus";
import Rails from '@rails/ujs';
import { displayFlashMessage } from '../../shared/scripts/flash_message_helper';
import { loadStripe } from '@stripe/stripe-js/pure';




const CARD_STYLE = {
  base: {
    color: "#FEFEFE",
    backgroundColor: "#2D2D31",
    fontSize: "14px",
    fontFamily: 'Arial, sans-serif',
    fontSmoothing: "antialiased",
    "::placeholder": {
      color: "#C9C9C9"
    }
  },
  invalid: {
    fontFamily: 'Arial, sans-serif',
    color: "#fa755a",
    iconColor: "#fa755a"
  }
};


export default class extends Controller {
  static targets = ['paymentForm', 'cardNumber', 'cardExpiry', 'cardCvc', 'cgv', 'cardError', 'payButton', 'payButtonText', 'spinner', 'zipCode']

  async connect() {
    this.stripe = await loadStripe(window.secrets.stripePublicKey);

    this.elementsValidity = { cardNumber: false, cardExpiry: false, cardCvc: false };
    this.payButtonTarget.disabled = true;

    const elements = this.stripe.elements();

    this.cardNumber = elements.create('cardNumber', {
      style: CARD_STYLE,
    });
    this.cardNumber.mount(this.cardNumberTarget);

    this.cardExpiry = elements.create('cardExpiry', {
      style: CARD_STYLE,
    });
    this.cardExpiry.mount(this.cardExpiryTarget);

    this.cardCvc = elements.create('cardCvc', {
      style: CARD_STYLE,
    });
    this.cardCvc.mount(this.cardCvcTarget);

    this.registerElements([this.cardNumber, this.cardExpiry, this.cardCvc]);
  }

  onPaymentFormSubmit(event) {
    event.preventDefault();
    Rails.ajax({
      url: "/checkout/payment_intents",
      type: "POST",
      success: (payload) => {
        this.onPaymentIntentsCreateSuccess(payload);
      },
      error: (error) => { 
        this.onPaymentIntentsCreateError(error);
      }
    });
  }

  onPaymentIntentsCreateSuccess(payload) {
    this.payWithCard(this.cardNumber, payload.payment_intent.client_secret, payload.purchase_id);
  }

  registerElements(elements) {
    elements.forEach((e) => {
      e.on("change", this.toggleDisabledPayButton.bind(this));
    })
  }

  toggleDisabledPayButton(event) {
    if (event.elementType) { // this handle event from Stripe Elements
      this.elementsValidity[event.elementType] = !event.error && event.complete;
    }
    if (this.elementsValidity.cardNumber == false || this.elementsValidity.cardExpiry == false || this.elementsValidity.cardCvc == false || !this.cgvTarget.checked) {
      this.payButtonTarget.disabled = true;
    } else {
      this.payButtonTarget.disabled = false;
    }
    this.cardErrorTarget.textContent = event.error ? event.error.message : "";
  }

  payWithCard(cardNumber, clientSecret, purchaseId) {
    this.loading(true);
    this.stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardNumber,
        billing_details: {
          address: {
            postal_code: this.zipCodeTarget.value
          }
        }
      }
    })
    .then((result) => {
      if (result.error) {
        Rails.ajax({ // s'il y a une erreur, on supprime l'objet purchase
          url: '/checkout/paiements/:id'.replace(':id', purchaseId),
          type: "DELETE"
        });
        this.showError(result.error.message);
      } else {
        Rails.ajax({ // sinon, on met à jour l'objet purchase côté serveur
         url: '/checkout/paiements/:id'.replace(':id', purchaseId),
          type: "PATCH",
          success: () => {
            Turbolinks.visit('/checkout/paiements/:id'.replace(':id', purchaseId));
          },
          error: () => {
            Turbolinks.visit('/checkout/paiements/:id'.replace(':id', purchaseId));
          }
        });
        this.loading(false);        
      }
    });
  }

  showError(errorMsgText) {
    this.loading(false);
    this.cardErrorTarget.textContent = errorMsgText;
  }

  onPaymentIntentsCreateError(payload) {
    displayFlashMessage(payload.message, payload.redirect_to);
  }

  loading(isLoading) {
    if (isLoading) {
      this.payButtonTarget.disabled = true;
      this.payButtonTarget.classList.add("hidden");
      this.spinnerTarget.classList.remove("hidden");
    } else {
      this.payButtonTarget.disabled = false;
      this.payButtonTarget.classList.remove("hidden");
      this.spinnerTarget.classList.add("hidden");
    }
  }
}
