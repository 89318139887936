import { TweenLite } from 'gsap';
import { Controller } from "stimulus";
import { ALERT_AUTO_DISMISS_DELAY } from '../constants';
import Turbolinks from 'turbolinks';

export default class extends Controller {
  static targets = [ "close" ];

  connect() {
    this.delayFadeAlert();
  } 

  makeInvisible($el) {
    $el.classList.add('is-invisible');
  }

  delayFadeAlert() {   
    const callback = () => {
      this.makeInvisible(this.element.parentElement);
      if (this.data.get('redirect-url')) {
        Turbolinks.visit(this.data.get('redirect-url'));
      }
    };
    TweenLite.delayedCall(ALERT_AUTO_DISMISS_DELAY, callback);
  }

  close() {
    this.makeInvisible(this.element.parentElement);
  }
}